<template>
  <a-form-item
    name="assetKind"
    label="Bien concerné par la donation"
    :rules="[
      {
        required: true,
        message: 'Veuillez sélectionner le bien concerné par la donation !',
      },
    ]"
  >
    <a-select v-model:value="computedFormState.assetKind">
      <a-select-option
        v-for="option in assetOptions"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <a-form-item
    v-if="computedFormState.assetKind === VALUABLE_OBJECT_ALREADY_REGISTERED"
    name="valuableObject"
    label="Bien déjà renseigné"
    :rules="[
      {
        required: true,
        message: 'Veuillez sélectionner le bien concerné par la donation !',
      },
    ]"
  >
    <SelectValuableObject v-model:value="computedFormState.valuableObject" />
  </a-form-item>
  <a-form-item
    style="position: relative"
    v-if="computedFormState.assetKind !== VALUABLE_OBJECT_ALREADY_REGISTERED"
    name="intrinsicValue"
    label="Valeur de la donation"
    :rules="[
      {
        required: true,
        message: 'Veuillez saisir la valeur de la donation !',
      },
    ]"
  >
    <div style="position: absolute; top: -30px; left: 165px">
      <Tooltip>
        <template #title>
          <p>
            Si le bien a été donné pour la moitié de sa valeur soit 50% et qu'il
            y a deux bénéficiaires à parts égales, alors les % qui doivent être
            entrés doivent être de 25% pour chacun
          </p>
        </template>
        <span style="font-weight: 600; color: rgb(33, 36, 78)">?</span>
      </Tooltip>
    </div>
    <InputCurrency
      v-model:value="computedFormState.intrinsicValue"
      :disabled="computedFormState.assetKind === null"
    />
  </a-form-item>

  <a-form-item
    name="proportionGiven"
    label="Proportion du bien donné"
    :rules="[
      {
        required: true,
        message: 'Veuillez saisir la proportion du bien donné !',
      },
    ]"
  >
    <InputPercentage v-model:value="computedFormState.proportionGiven" />
  </a-form-item>

  <a-form-item
    name="givenIn"
    label="Donné en"
    :rules="[{ required: true, message: 'Veuillez saisir cette valeur !' }]"
  >
    <a-select v-model:value="computedFormState.givenIn">
      <a-select-option
        v-for="option in givenInOptions"
        :value="option.value"
        :key="option.value"
      >
        {{ option.label }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <a-form-item
    name="donor"
    label="Donateur"
    :rules="[{ required: true, message: 'Veuillez saisir le donateur' }]"
  >
    <a-select v-model:value="computedFormState.donor">
      <a-select-option
        v-for="option in donors"
        :value="option._id"
        :key="option._id"
      >
        {{ option.firstName }} {{ option.lastName }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <a-form-item name="comment" label="Commentaire sur le bien">
    <a-textarea v-model:value="computedFormState.comment" />
  </a-form-item>
</template>

<script>
import {
  assetOptions,
  givenInOptions,
  kindOptions,
  natureOptions,
  VALUABLE_OBJECT_ALREADY_REGISTERED,
  varietyOptions,
} from "@/views/App/Transmission/Donations/constants";
import InputCurrency from "@/components/Fields/InputCurrency";
import InputPercentage from "@/components/Fields/InputPercentage";
import SelectValuableObject from "@/views/App/Transmission/Donations/SelectValuableObject";
import _ from "lodash";
import Tooltip from "@/components/Tooltips/Tooltip.vue";

export default {
  name: "TabObjectOfDonation",
  components: { Tooltip, SelectValuableObject, InputPercentage, InputCurrency },
  props: ["formState", "familyMembers", "headsOfFamily"],
  data() {
    return {
      kindOptions,
      natureOptions,
      assetOptions,
      varietyOptions,
      givenInOptions,
      VALUABLE_OBJECT_ALREADY_REGISTERED,
    };
  },
  computed: {
    donors() {
      return _.chain(this.familyMembers)
        .filter((member) => this.headsOfFamily.indexOf(member._id) !== -1)
        .value();
    },
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
};
</script>

<style scoped></style>
