<template>
  <a-select
    ref="select"
    v-model:value="value1"
    :options="options1"
    @focus="focus"
    @change="handleChange"
  >
  </a-select>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  name: "SelectUser",
  props: ["user", "conjugalPartner", "setCurrentUser", "currentUser"],
  setup(props) {
    const value1 = ref(props.currentUser);
    const options1 = [
      {
        value: props.user.customerId,
        label: props.user.customerName,
      },
    ];

    // Security if conjugalPartner is undefined.
    if (props.conjugalPartner) {
      options1.push({
        value: props.conjugalPartner._id,
        label: `${props.conjugalPartner.firstName} ${props.conjugalPartner.lastName}`,
      })
    }

    const focus = () => {};

    const handleChange = (value) => {
      value1.value = value;
      props.setCurrentUser(value);
    };

    return {
      focus,
      handleChange,
      value1,
      options1,
    };
  },
});
</script>

<style scoped></style>
