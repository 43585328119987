<template>
  <div class="workspace">
    <div v-if="hasHeaderSlot" class="header">
      <div><slot name="headerLeft"></slot></div>
      <div><slot name="headerRight"></slot></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Workspace",
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.headerLeft || !!this.$slots.headerRight;
    },
  },
};
</script>

<style scoped>
.workspace {
  background: var(--kermony-blanc);
  padding: 16px;
  border-radius: 5px;
  min-height: calc(100% - 25px);
  margin-bottom: 25px;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 24px;
}
</style>
