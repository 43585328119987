<template>
  <div class="list-avatar-circle">
    <AvatarCircle
      v-for="(item, index) in items.slice().reverse()"
      :key="item.src"
      :src="item.src"
      :alt="item.label"
      :size="_size"
      :style="{
        border:
          index % 2 === 0
            ? '1px solid var(--kermony-office-rose)'
            : '1px solid var(--kermony-office-violet)',
      }"
    />
  </div>
</template>

<script>
import AvatarCircle from "@/components/Avatars/AvatarCircle";
const DEFAULT_SIZE = 24;
export default {
  name: "ListAvatarCircle",
  components: { AvatarCircle },
  props: ["items", "size"],
  computed: {
    _size() {
      return this.size || DEFAULT_SIZE;
    },
  },
};
</script>

<style scoped>
.list-avatar-circle {
  display: flex;
  flex-direction: row-reverse;
}

.list-avatar-circle > * {
  margin-right: -8px;
}

.list-avatar-circle > :first-child {
  margin-right: 0px;
}
</style>
