<template>
  <a-modal
    v-model:visible="computedVisible"
    width="80%"
    @ok="onOk"
    @cancel="onCancel"
    :bodyStyle="{ padding: '32px', paddingTop: 0 }"
  >
    <template #title>
      <Heading6>{{ modalTitle }}</Heading6>
    </template>
    <template #footer v-if="computedActiveKey === 1">
      <a-button key="back" @click="onCancel">ANNULER</a-button>
      <a-button key="submit" type="primary" @click="computedActiveKey = 2"
        >SUIVANT</a-button
      >
    </template>
    <template #footer v-else-if="computedActiveKey === 4">
      <a-button key="back" @click="computedActiveKey--">PRÉCÉDENT</a-button>
      <a-button key="submit" type="primary" @click="onOk">{{
        textConfirmButton
      }}</a-button>
    </template>
    <template #footer v-else>
      <a-button key="back" @click="computedActiveKey--">PRÉCÉDENT</a-button>
      <a-button key="submit" type="primary" @click="computedActiveKey++"
        >SUIVANT</a-button
      >
    </template>

    <BaseFormDonation
      v-model:formState="computedFormState"
      v-model:activeKey="computedActiveKey"
      v-model:formRef="computedFormRef"
      :familyMembers="familyMembers"
      :headsOfFamily="headsOfFamily"
    />
  </a-modal>
</template>

<script>
import BaseFormDonation from "@/views/App/Transmission/Donations/Form/BaseFormDonation";
import Heading6 from "@/components/Texts/Heading6";
export default {
  name: "ModalFormDonation",
  components: { Heading6, BaseFormDonation },
  props: [
    "modalTitle",
    "familyMembers",
    "formState",
    "activeKey",
    "onOk",
    "onCancel",
    "textConfirmButton",
    "visible",
    "formRef",
    "headsOfFamily",
  ],
  computed: {
    computedVisible: {
      get: function () {
        return this.visible;
      },
      set: function (newOptions) {
        this.$emit("update:visible", newOptions);
      },
    },
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update:formState", newOptions);
      },
    },
    computedActiveKey: {
      get: function () {
        return this.activeKey;
      },
      set: function (newOptions) {
        this.$emit("update:activeKey", newOptions);
      },
    },
    computedFormRef: {
      get: function () {
        return this.formRef;
      },
      set: function (newOptions) {
        this.$emit("update:formRef", newOptions);
      },
    },
  },
};
</script>

<style scoped>
.ant-modal-header {
  padding: 32px 32px 13px 32px;
}
.ant-modal-close {
  top: 15px;
  right: 15px;
}
</style>
