<template>
  <div class="header">
    <Heading6><slot></slot> </Heading6>
    <Button
      v-if="showBtn"
      @click="click"
      size="small"
      theme="secondary"
      style="white-space: nowrap"
      >Voir plus</Button
    >
  </div>
</template>

<script>
import Heading6 from "@/components/Texts/Heading6";
import Button from "@/components/Buttons/Button";
export default {
  name: "Header",
  components: { Button, Heading6 },
  props: ["click", "showBtn"],
};
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
}
</style>
