<template>
  <span class="overline"><slot></slot></span>
</template>

<script>
export default {
  name: "Overline",
};
</script>

<style scoped>
.overline {
  /* Weissenhof/Overline */

  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--kermony-gris-2);
}
</style>
