<template>
  <Tooltip placement="right">
    <template #title>
      <slot></slot>
    </template>
    <Icon size="medium" name="info" color="var(--kermony-bleu-2)"></Icon>
  </Tooltip>
</template>

<script>
import Tooltip from "@/components/Tooltips/Tooltip";
import Icon from "@/components/Icons/Icon";
export default {
  name: "TooltipClauses",
  components: { Icon, Tooltip },
};
</script>

<style scoped></style>
