<template>
  <Workspace>
    <template #headerLeft>
      <Heading5>Mes clauses bénéficiaires</Heading5>
    </template>
    <template #headerRight>
      <slot name="headerRight"></slot>
    </template>
    <div
      style="
        display: flex;
        gap: 16px;
        flex-direction: column;
        margin-bottom: 4px;
      "
    >
      <Caption
        style="color: var(--kermony-office-bleu-1)"
        v-if="items?.length > 0"
        >Vous trouverez sur la droite la liste des contrats d'assurance-vie pour lesquels nous avons une information complète.
        Pour tous les contrats qui ne seraient pas présents, merci nous transmettre l’ensemble des informations ainsi que les clauses bénéficiaires applicables afin que nous puissions vous donner une estimation au plus juste de la répartition des capitaux entre les différents bénéficiaires. La rédaction des clauses bénéficiaires doit faire l’objet d'une attention particulière.</Caption
      >
      <Caption v-else style="color: var(--kermony-office-bleu-1)">
        Vous n’avez pas de clauses bénéficiaires pour l’instant.
      </Caption>
      <div class="grid">
        <Card
          style="height: max-content"
          :style="items?.length > 0 ? {} : { opacity: 0 }"
        >
          <Heading6 v-if="items?.length > 0"
            >Bénéficiaires en cas de décès</Heading6
          >
          <Overline v-if="items?.length > 0">TOTAL</Overline>
          <Heading5
            v-if="items?.length > 0 && total !== null"
            style="color: #464e5f"
          >
            {{ formatAmount(total) }}
          </Heading5>
          <div class="chart-wrapper">
            <canvas
              height="200"
              style="margin: 0 auto; width: 100%"
              ref="barchart_beneficiaryClauses"
            ></canvas>
          </div>
          <Legends :items="legends" v-if="items?.length > 0"></Legends>
        </Card>
        <div class="vertical-list" v-if="items?.length > 0">
          <Card v-for="(item, index) in items" :key="index">
            <Heading6
              style="color: var(--kermony-office-bleu-1); margin-bottom: 8px"
            >
              {{ item.product.name }}
              {{ item.product.accountNumber }}
              <TooltipClauses
                v-if="item.typeContract === 'Contrat mineur'"
                class="tooltip-right"
              >
                Le contrat étant souscrit par un enfant mineur, il est géré sous
                administration légale ou conventionnelle jusqu’à ses 18 ans.
                Aussi, le chiffrage des avoirs dénoués n’en tient pas compte.
              </TooltipClauses>
              <TooltipClauses
                v-if="
                  item.typeContract ===
                  'Co-souscription avec dénouement 2nd deces'
                "
                class="tooltip-right"
              >
                Ce contrat étant en co-souscription avec dénouement au second
                décès, il n’est pas pris en compte dans cette simulation
              </TooltipClauses>
            </Heading6>
            <div class="left-right">
              <Body1 style="color: var(--kermony-office-rose)">Montant</Body1>
              <Heading6 style="color: var(--kermony-office-rose)">{{
                formatAmount(item.product.amount)
              }}</Heading6>
            </div>
            <div class="left-right">
              <Body1>Bénéficiaires</Body1>
              <ListAvatarCircle
                :items="beneficiariesToUrls(item.beneficiaries)"
              ></ListAvatarCircle>
            </div>

            <a-collapse
              v-if="item.ignoredContent !== true"
              class="k-collapse"
              expandIconPosition="right"
              :expandIcon="getExpandIcon"
            >
              <a-collapse-panel :key="index">
                <template #header
                  ><Body1 class="no-select body1"
                    >Détails de la clause</Body1
                  ></template
                >
                <p>{{ item.content }}</p>
              </a-collapse-panel>
            </a-collapse>
          </Card>
        </div>
      </div>
    </div>
  </Workspace>
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { stringSimilarity } from "string-similarity-js";

import Workspace from "@/components/Workspaces/Workspace";
import Heading5 from "@/components/Texts/Heading5";
import Caption from "@/components/Texts/Caption";
import Heading6 from "@/components/Texts/Heading6";
import Card from "@/components/Cards/Card";
import Overline from "@/components/Texts/Overline";
import Legends from "@/views/App/Transmission/Components/Legends";
import { toLegends } from "@/views/App/Transmission/ChartUtils";
import Body1 from "@/components/Texts/Body1";
import ListAvatarCircle from "@/components/Avatars/ListAvatarCircle";
import Icon from "@/components/Icons/Icon";
import TooltipClauses from "./TooltipClauses";
import { getAvatarUrl } from "@/utils/avatar";
import { formatAmount } from "@/utils/number";

export default {
  name: "PageBeneficiaryClauses",
  components: {
    //Icon,
    ListAvatarCircle,
    Body1,
    Legends,
    Overline,
    Card,
    Heading6,
    Caption,
    Heading5,
    Workspace,
    TooltipClauses,
  },
  props: ["items", "currentUser", "currentUserName", "total"],
  mounted() {
    this.$store.commit("updateHeader", { title: "", subtitle: "" });
    this.drawChart();
  },
  data() {
    return {
      beneficiariesCount: 0,
      legends: [],
    };
  },
  watch: {
    currentUser: function () {
      // watch it
      this.drawChart();
    },
  },
  methods: {
    getExpandIcon(props) {
      const name = props.isActive ? "chevron-up" : "chevron-down";

      return (
        <Icon
          name={name}
          size="medium"
          color="var(--kermony-office-bleu-1)"
        ></Icon>
      );
    },
    beneficiariesToUrls(beneficiaries) {
      return _.map(beneficiaries, this.beneficiaryToUrl);
    },
    beneficiaryToUrl(beneficiary) {
      return {
        label: beneficiary.beneficiary,
        src: beneficiary.avatar ? getAvatarUrl(beneficiary.avatar) : getAvatarUrl(null, beneficiary.beneficiary)
      };
    },
    olderThanTwoYears(year) {
      return moment().diff(moment(year), "days") > 365 * 2;
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    numberWithSpacesFloating(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    formatAmount,

    drawChart() {
      if (!(this.items?.length > 0)) {
        return;
      }

      let beneficiaries = {};

      _.each(this.items, (item) => {
        // Count the total without "amount" beneficiaries.
        let productTotal = _.reduce(
          item.beneficiaries,
          function (number, benef) {
            if (benef.kind === "amount") return number - benef.value;

            return number;
          },
          item.product.amount
        );

        // Loop on each beneficiary
        _.each(item.beneficiaries, (beneficiary) => {
          let amount;

          if (beneficiary.kind === "percentage")
            amount = (productTotal / 100) * beneficiary.value;
          else amount = beneficiary.value;

          if (item.multiplyAmountInGraph) amount = item.product.amount;

          // If item need to be printed into graph.
          if (item.ignoredInGraph !== true) {
            if (
              stringSimilarity(
                beneficiary.beneficiary,
                this.currentUserName,
                1
              ) > 0.9
            )
              return;

            if (!beneficiaries[beneficiary.beneficiary])
              beneficiaries[beneficiary.beneficiary] = {};

            if (!beneficiaries[beneficiary.beneficiary][item.kind])
              beneficiaries[beneficiary.beneficiary][item.kind] = amount;
            else
              beneficiaries[beneficiary.beneficiary][item.kind] =
                beneficiaries[beneficiary.beneficiary][item.kind] + amount;
          }

          this.beneficiariesCount = Object.keys(beneficiaries).length;
        });
      });

      let labels = [];

      let data990i = [];
      let data757B = [];

      _.each(beneficiaries, (item, key) => {
        labels.push(key);
        data990i.push(item["990i"] || 0);
        data757B.push(item["757B"] || 0);
      });

      const colors = ["#AC2369", "#71C8DA"];

      const data = {
        labels: labels,
        datasets: [
          {
            label: "Capitaux décès (art. 990 i)",
            backgroundColor: _.first(colors),
            data: data990i,
            borderRadius: 5,
          },
          {
            label: "Capitaux décès (art. 757 B)",
            backgroundColor: _.last(colors),
            data: data757B,
            borderRadius: 5,
          },
        ],
      };

      this.legends = toLegends(
        [
          [
            <span className="aligner-v" style="gap: 4px;">
              Capitaux décès{" "}
              <TooltipClauses>
                Les primes versées avant les 70 ans du souscripteur bénéficient
                de l’application d’un abattement, par bénéficiaire désigné, de
                152.500 € sur la valeur de rachat du contrat au jour du décès de
                l’assuré, avant de faire l’objet d’une imposition forfaitaire au
                taux de 20% pour la part égale ou inférieure à 700.000 € et au
                taux de 31,65% au-delà – Article 990 I du CGI.
              </TooltipClauses>
            </span>,
            "art. 990 I",
          ],
          [
            <span className="aligner-v" style="gap: 4px;">
              Capitaux décès{" "}
              <TooltipClauses>
                Les primes versées après les 70 ans du souscripteur bénéficient,
                quant à elles, d’un abattement global de 30.500 € qui se
                répartit entre les bénéficiaires désignés et font l’objet d’une
                imposition dont le taux est déterminé par l’application du
                barème des droits de succession, après déduction des abattements
                de droit commun (ex : 100.000 € par parent et enfant) – Article
                757 B du CGI.
              </TooltipClauses>
            </span>,
            "art. 757 B",
          ],
        ],
        colors
      );

      let hovering = false,
        tooltip = document.getElementById("tooltip"),
        tooltips = [
          "capitaux n'entrant pas dans l'actif succesoral (article 990i du CGI)",
          "capitaux dont seules les primes versées entre à l'actif successoral (article 757 B du CGI)",
        ];
      const config = {
        type: "bar",
        data: data,
        options: {
          //      chart.options.plugins.legend.position = 'bottom';
          plugins: {
            title: {
              display: false,
            },

            legend: {
              display: false,
              position: "bottom",
              onHover: function (event, legendItem) {
                if (hovering) {
                  return;
                }
                hovering = true;
                tooltip.innerHTML = tooltips[legendItem.datasetIndex];
                tooltip.style.display = "block";
                //tooltip.style.left = event.x  + "px";
                tooltip.style.top = event.y + 10 + "px";
              },
              onLeave: function () {
                hovering = false;
                tooltip.style.display = "none";
                tooltip.innerHTML = "";
              },
            },
          },

          //responsive: false,
          //maintainAspectRatio: false,

          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
              ticks: {
                // Include a dollar sign in the ticks
                callback: (value) => {
                  return this.formatAmount(value);
                },
              },
            },
          },
        },
      };

      let ctx = this.$refs.barchart_beneficiaryClauses;
      this._drawChart(ctx, config);
    },

    _drawChart(ctx, config) {
      this.chartInstance?.destroy();
      // eslint-disable-next-line no-undef,no-unused-vars
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style>
.tooltip-right {
  float: right;
}

#tooltip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-collapse-arrow {
  transform: translateY(-2px);
}

.vertical-list > .card {
  height: initial;
  min-height: initial;
}

.vertical-list {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.grid > .card,
.vertical-list > .card {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.vertical-list > .card {
  gap: 8px;
}

.grid > .card {
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  grid-template-rows: initial;
  height: initial;
  align-items: flex-start;
}

.grid > .card > * {
  width: 100%;
}

.chart-wrapper {
  background: rgba(248, 244, 245, 0.5);
  border-radius: 5px;
  padding: 32px;
  width: 100%;
}

.left-right {
  display: flex;
}

.left-right > :last-child {
  margin-left: auto;
}

.k-collapse.ant-collapse {
  border: none;
  background-color: transparent;
}

.k-collapse .ant-collapse-item {
  border: none;
}
.k-collapse .ant-collapse-header {
  padding: 0 !important;
}

.k-collapse .ant-collapse-content {
  border: none;
}
.k-collapse .ant-collapse-arrow {
  right: 0 !important;
}
</style>
