<template>
  <a-form-item
    name="name"
    label="Intitulé de la donation"
    :rules="[
      {
        required: true,
        message: 'Veuillez saisir l\'intitulé de la donation !',
      },
    ]"
  >
    <a-input v-model:value="computedFormState.name" />
  </a-form-item>

  <a-form-item
    name="kind"
    label="Type de donation"
    :rules="[
      {
        required: true,
        message: 'Veuillez selectionner le type de la donation !',
      },
    ]"
  >
    <a-select v-model:value="computedFormState.kind">
      <a-select-option
        v-for="option in kindOptions"
        :value="option.value"
        :key="option.value"
        >{{ option.label }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <a-form-item
    name="nature"
    label="Nature de la donation"
    :rules="[
      {
        required: true,
        message: 'Veuillez saisir la nature de la donation !',
      },
    ]"
  >
    <a-select v-model:value="computedFormState.nature">
      <a-select-option
        v-for="option in natureOptions"
        :value="option.value"
        :key="option.value"
        >{{ option.label }}
      </a-select-option>
    </a-select>
  </a-form-item>

  <!--  <a-form-item-->
  <!--    name="variety"-->
  <!--    label="Forme de la donation"-->
  <!--    :rules="[-->
  <!--      {-->
  <!--        required: true,-->
  <!--        message: 'Veuillez sélectionner la forme de la donation !',-->
  <!--      },-->
  <!--    ]"-->
  <!--  >-->
  <!--    <a-select v-model:value="computedFormState.variety">-->
  <!--      <a-select-option-->
  <!--        v-for="option in varietyOptions"-->
  <!--        :value="option.value"-->
  <!--        :key="option.value"-->
  <!--      >-->
  <!--        {{ option.label }}-->
  <!--      </a-select-option>-->
  <!--    </a-select>-->
  <!--  </a-form-item>-->

  <a-form-item
    name="donationDate"
    label="Date de la donation"
    :rules="[
      {
        required: true,
      },
    ]"
  >
    <a-date-picker
      class="w-100"
      v-model:value="computedFormState.donationDate"
    />
  </a-form-item>
</template>

<script>
import {
  assetOptions,
  givenInOptions,
  kindOptions,
  natureOptions,
  varietyOptions,
} from "@/views/App/Transmission/Donations/constants";

export default {
  name: "TabInformations",
  props: ["formState"],
  data() {
    return {
      kindOptions,
      natureOptions,
      assetOptions,
      varietyOptions,
      givenInOptions,
    };
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
};
</script>

<style scoped></style>
