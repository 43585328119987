import _ from "lodash";

export const populateValues = (formState, values) => {
  let newValues = {
    ...values,
  };

  _.each(formState, (val, key) => {
    if (!_.has(values, key)) {
      newValues[key] = val;
    }
  });

  return newValues;
};

// catchSubmitForm expect to be use in .catch of axios request
export const catchSubmitForm = (error) => {
  if (!error.response) {
    return alert("Une erreur est survenue");
  }

  if (error.response.status === 400) {
    return alert("Tous les champs ne sont pas complétés");
  }

  alert("Une erreur est survenue");
};
