<template>
  <div class="card-avatar">
    <Avatar size="65" :src="src"></Avatar>
    <div class="text">{{ label }}</div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatars/Avatar";
export default {
  name: "CardAvatar",
  components: { Avatar },
  props: ["src", "label"],
};
</script>

<style scoped>
.card-avatar {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(109, 45, 104, 0.3);
  border-radius: 5px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  width: 87px;
  height: 107px;
  display: grid;
  text-align: center;
}
.card-avatar > * {
  margin-left: 3px;
  margin-right: 3px;
}

/* text of card avatar seem to not match any existing text */
.text {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17.1516px;
  line-height: 22px;
  letter-spacing: 0.527743px;
  color: var(--kermony-office-violet);
}
</style>
