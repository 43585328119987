<template>
  <ChartContainer
    :total="total !== null ? formatAmount(total, { cents: false }) : 0"
    :legends="legends"
    :legendsScrollable="true"
  >
    <canvas ref="chart"></canvas>
  </ChartContainer>
</template>

<script>
import ValuableObjectApi from "@/api/valuableObject";
import _ from "lodash";
import { VALUABLE_OBJECT_ALREADY_REGISTERED } from "@/views/App/Transmission/Donations/constants";
import { formatAmount, roundToTwoDecimalPlaces } from "@/utils/number";
import FamilyApi from "@/api/family";
import { getChartConfig, toLegends } from "@/views/App/Transmission/ChartUtils";
import ChartContainer from "@/views/App/Transmission/Components/ChartContainer";
import { getAvatarUrl } from "@/utils/avatar";

export default {
  name: "GraphDonations",
  components: { ChartContainer },
  props: ["donations"],
  data() {
    return {
      formatAmount,
      legends: [],
      total: null,
    };
  },
  mounted() {
    this.getDonations(this.donations);
  },
  watch: {
    donations: function (newVal) {
      // watch it
      this.getDonations(newVal);
    },
  },
  methods: {
    async getDonations(donations) {
      if (donations === null)
        return null;

      const members = await FamilyApi.getFamilyMembers();
      const valuableObjects = await ValuableObjectApi.getValuableObjects();

      let donationsByBeneficiary = {};

      _.each(donations, (item) => {
        _.each(item.beneficiaries, (b) => {
          if (!(b.beneficiary in donationsByBeneficiary)) {
            let findBeneficiary = _.find(members.familyMembers, {_id: b.beneficiary});

            if (!findBeneficiary) {
              console.log(`[Donations] Unable to found ${b.beneficiary}, removed from beneficiary array.`)
              return;
            }

            donationsByBeneficiary[b.beneficiary] = {
              beneficiary: findBeneficiary,
              amount: 0,
            };
          }

          if (item.assetKind === VALUABLE_OBJECT_ALREADY_REGISTERED) {
            const found = _.find(valuableObjects, { _id: item.valuableObject });
            if (found !== undefined) {
              donationsByBeneficiary[b.beneficiary].amount +=
                roundToTwoDecimalPlaces(
                  ((found.currentValue * item.proportionGiven) / 100) *
                    (b.percentage / 100)
                );
            }
          } else {
            donationsByBeneficiary[b.beneficiary].amount +=
              roundToTwoDecimalPlaces(
                ((item.intrinsicValue * item.proportionGiven) / 100) *
                  (b.percentage / 100)
              );
          }
        });
      });

      // Chart labels
      const labels = _.map(donationsByBeneficiary, (item) => [
        `${item.beneficiary.firstName} ${item.beneficiary.lastName}`,
        formatAmount(item.amount),
      ]);

      // const colors = [
      //   "#AC2369",
      //   "#E0A3B9",
      //   "#F8E8ED",
      //   "#D78CA9",
      //   "#B84378",
      //   "#F0D2DD",
      //   "#C35C88",
      //   "#E9B9CA",
      //   "#CD7498",
      //   "#DA9EB5",
      // ];

      const colors = [
        "#AC2369",
        "#F4CCE1",
        "#EA9AC3",
        "#E067A5",
        "#801A4E",
        "#561134"
      ];

      const avatars = await Promise.all(
        _.map(donationsByBeneficiary, (item) => {
          return getAvatarUrl(item.beneficiary._id);
        })
      );

      this.legends = toLegends(labels, colors, avatars);

      const values = _.map(donationsByBeneficiary, (item) => item.amount);
      this.total = _.sum(values);

      // Chart data
      const data = {
        labels: labels,
        datasets: [
          {
            data: _.map(donationsByBeneficiary, (item) => item.amount),
            backgroundColor: colors,
          },
        ],
      };

      // Chart config
      const config = getChartConfig(data);
      const ctx = this.$refs.chart;

      this.drawChart(ctx, config);
    },
    drawChart(ctx, config) {
      this.chartInstance?.destroy();
      // eslint-disable-next-line no-undef,no-unused-vars
      this.chartInstance = new Chart(ctx, config);
    },
  },
};
</script>

<style scoped></style>
