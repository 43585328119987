<template>
  <div>
    <ModalFormDonation
      :modalTitle="modalTitle"
      :familyMembers="familyMembers"
      :headsOfFamily="headsOfFamily"
      v-model:formState="formState"
      v-model:activeKey="activeKey"
      v-model:visible="visible"
      v-model:formRef="formRef"
      :onOk="onOk"
      :onCancel="onCancel"
      textConfirmButton="SAUVEGARDER"
    />
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import DonationApi from "@/api/donation";

import { VALUABLE_OBJECT_ALREADY_REGISTERED } from "@/views/App/Transmission/Donations/constants";
import _ from "lodash";
import dayjs from "dayjs";
import ModalFormDonation from "@/views/App/Transmission/Donations/Form/ModalFormDonation";
import { catchSubmitForm } from "@/views/App/ValuableObjects/utils";

export default defineComponent({
  name: "FormEditDonation",
  components: {
    ModalFormDonation,
  },
  props: [
    "onHide",
    "donation",
    "buttonTitle",
    "buttonStyle",
    "modalTitle",
    "familyMembers",
    "updateDonation",
    "headsOfFamily",
  ],
  setup(props) {
    const activeKey = ref(1);

    const formRef = ref();
    const visible = ref(true);
    const formState = reactive({
      ..._.omit(props.donation, [
        "_id",
        "createdAt",
        "updatedAt",
        "family",
        "internal_clause_inalienabilite_temporaire",
        "internal_clause_rente",
        "internal_clause_residuo",
        "internal_clause_retour_conventionnel",
        "internal_clause_subrogation",
        "internal_interdiction_de_disposer_des_biens_a_titre_gratuit",
        "internal_interdiction_hypotheque",
      ]),
      donationDate: !props.donation.donationDate ? null : dayjs(props.donation.donationDate),
      beneficiaries: _.map(props.donation.beneficiaries, (beneficiary) => {
        return {
          beneficiary: beneficiary.beneficiary,
          percentage: beneficiary.percentage,
        };
      }),
    });

    const onOk = () => {
      formRef.value
        .validateFields()
        .then(() => {
          let formValues = { ...formState };

          if (formValues.assetKind === VALUABLE_OBJECT_ALREADY_REGISTERED) {
            formValues.intrinsicValue = null;
          } else {
            formValues.valuableObject = null;
          }

          DonationApi.editDonation(props.donation._id, formValues)
            .then((data) => {
              props.updateDonation(data);

              // Close popup
              visible.value = false;
              formRef.value.resetFields();

              props.onHide();
            })
            .catch(catchSubmitForm);
        })
        .catch((error) => {
          // Form invalid, e.g. field required without value

          alert("Tous les champs ne sont pas complétés");
          // TODO redirect to "first" tab with an error like "create form"
          console.error("[Donation] Error:", error);
        });
    };

    const onCancel = () => {
      formRef.value.resetFields();
      activeKey.value = 1;
      props.onHide();
    };

    return {
      formState,
      formRef,
      visible,
      onOk,
      onCancel,
      activeKey,
    };
  },
});
</script>
