// deduction available
import HTTP from "@/axios";

export default {
  getMaxDeduction() {
    return new Promise((resolve, reject) => {
      HTTP.get("donations/max_deduction")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDonations(selectedUser) {
    return new Promise((resolve, reject) => {
      HTTP.get("donations/", { params: { selectedUser } })
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createDonation(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("donations/", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editDonation(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.put(`donations/${id}/`, data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteDonation(id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`donations/${id}/`)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
