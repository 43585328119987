<template>
  <Avatar class="circle" :src="src" :size="size" />
</template>

<script>
import Avatar from "@/components/Avatars/Avatar";
export default {
  name: "AvatarCircle",
  components: { Avatar },
  props: ["src", "size"],
};
</script>

<style scoped>
.circle {
  border-radius: 50%;
}
</style>
