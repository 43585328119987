<template>
  <a-select
    v-if="valuableObjects !== null"
    :value="value"
    @change="(newValue) => $emit('update:value', newValue)"
  >
    <a-select-option
      v-for="option in valuableObjects"
      :value="option._id"
      :key="option._id"
    >
      <img
        class="img"
        :src="require(`@/assets/valuable_objects/${option.kind}.png`)"
      />
      {{ option.name }}
      <span class="float-right">{{ formatAmount(option.currentValue) }}</span>
    </a-select-option>
  </a-select>
</template>

<script>
import ValuableObjectsApi from "@/api/valuableObject";
import { formatAmount } from "@/utils/number";
import _ from "lodash";

export default {
  name: "SelectValuableObject",
  props: ["value"],
  data() {
    return {
      valuableObjects: null,
      formatAmount,
    };
  },
  mounted() {
    ValuableObjectsApi.getValuableObjects().then((data) => {
      this.valuableObjects = _.orderBy(data, ["name"]);
    });
  },
};
</script>

<style scoped>
.img {
  height: 15px;
  position: relative;
  top: -1.5px;
  margin-right: 5px;
}
</style>
