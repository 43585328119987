<template>
  <div class="arrows">
    <Button
      @click="goLeft"
      style="padding: 4px"
      size="small"
      theme="tertiary"
      icon-right="arrow-left"
      icon-size="medium"
      :disabled="disabledLeft"
    ></Button>
    <Button
      @click="goRight"
      style="padding: 4px"
      size="small"
      theme="tertiary"
      icon-right="arrow-right"
      icon-size="medium"
      :disabled="disabledRight"
    ></Button>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";

const cardSize = 87;
export default {
  name: "Arrows",
  components: { Button },
  props: ["refContainer"],
  data() {
    return {
      position: 0,
      disabledLeft: true,
      disabledRight: false,
    };
  },

  methods: {
    goRight() {
      this.position += 1;
      const tmp = this.refContainer.offsetWidth / (cardSize + 8);
      const x =
        (cardSize + 8) * Math.round(this.refContainer.offsetWidth / cardSize) -
        (cardSize + 8) * tmp;
      this.refContainer.scrollTo(
        cardSize * this.position + 8 + 8 + 8 * this.position + x,
        0
      );

      this.disabledLeft = false;
      this.disabledRight =
        Math.abs(
          Math.round(
            this.refContainer.scrollLeft + this.refContainer.offsetWidth
          ) - Math.round(this.refContainer.scrollWidth)
        ) < 2;
    },
    goLeft() {
      this.position -= 1;
      const tmp = this.refContainer.offsetWidth / (cardSize + 8);
      const x =
        (cardSize + 8) * Math.round(this.refContainer.offsetWidth / cardSize) -
        (cardSize + 8) * tmp;
      this.refContainer.scrollTo(
        cardSize * this.position + 8 + 8 + 8 * this.position + x,
        0
      );
      this.disabledLeft = this.refContainer.scrollLeft === 0;
      this.disabledRight = false;
    },
  },
};
</script>

<style scoped>
.arrows {
  display: flex;
  gap: 8px;
  justify-content: center;
}
</style>
