<template>
  <a-form-item
    name="beneficiaries"
    label="Au profit de"
    :rules="[
      {
        required: true,
        message: 'Veuillez saisir les bénéficiaires',
      },
    ]"
  >
    <div
      v-for="(beneficiary, index) in computedFormState.beneficiaries"
      :key="beneficiary.beneficiary ?? 'new'"
      style="
        display: flex;
        margin-bottom: 8px;
        vertical-align: baseline;
        gap: 10px;
      "
    >
      <a-form-item
        style="flex-grow: 1"
        :name="['beneficiaries', index, 'beneficiary']"
        :rules="{
          required: true,
          message: 'Missing beneficiary',
        }"
      >
        <a-select v-model:value="beneficiary.beneficiary">
          <a-select-option
            v-for="option in listBeneficiaries(index)"
            :value="option._id"
            :key="option._id"
          >
            {{ option.firstName }} {{ option.lastName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span style="margin-top: 5px">à</span>

      <a-form-item
        :name="['beneficiaries', index, 'percentage']"
        :rules="{
          required: true,
          message: 'Missing percentage',
        }"
      >
        <InputPercentage
          v-model:value="beneficiary.percentage"
          :max="getMax(index)"
          placeholder="Percentage"
          style="width: 90px"
        />
        <div>en % du bien</div>
      </a-form-item>

      <MinusCircleOutlined
        style="margin-top: 8px"
        @click="removeBeneficiary(index)"
      />
    </div>
    <a-form-item>
      <a-button type="dashed" block @click="addBeneficiary">
        <PlusOutlined style="position: relative; top: -3px" />
        <span>Ajouter un bénéficiaire</span>
      </a-button>
    </a-form-item>
  </a-form-item>
</template>

<script>
import InputPercentage from "@/components/Fields/InputPercentage";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import _ from "lodash";
export default {
  name: "TabBeneficiaries",
  components: { InputPercentage, MinusCircleOutlined, PlusOutlined },
  props: ["formState", "familyMembers"],
  methods: {
    removeBeneficiary(index) {
      if (index !== -1) {
        this.computedFormState.beneficiaries.splice(index, 1);
      }
    },
    addBeneficiary() {
      this.computedFormState.beneficiaries.push({
        percentage: null,
        beneficiary: null,
      });
    },
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
    getMax() {
      return (index) => {
        let acc = 0;

        for (let i = 0; i < this.computedFormState.beneficiaries.length; i++) {
          if (i !== index) {
            acc += this.computedFormState.beneficiaries[i].percentage;
          }
        }

        return 100 - acc;
      };
    },
    listBeneficiaries() {
      return (index) => {
        let alreadyUsed = [];

        if (this.computedFormState.donor !== null) {
          alreadyUsed.push(this.computedFormState.donor);
        }

        for (let i = 0; i < this.computedFormState.beneficiaries.length; i++) {
          if (i !== index) {
            alreadyUsed.push(
              this.computedFormState.beneficiaries[i].beneficiary
            );
          }
        }

        return _.filter(
          this.familyMembers,
          (member) => alreadyUsed.indexOf(member._id) === -1
        );
      };
    },
  },
};
</script>

<style scoped></style>
