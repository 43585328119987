<template>
  <div>
    <div :style="{ display: showBeneficiaryClausesDetails ? 'block' : 'none' }">
      <div class="container">
        <Button
          theme="tertiary"
          v-on:click="setShowBeneficiaryClausesDetails(false)"
          class="button-back"
        >
          <ArrowLeft /> MA TRANSMISSION
        </Button>

        <div></div>

        <PageBeneficiaryClauses
          v-if="showBeneficiaryClausesDetails"
          :items="getCurrentBeneficiaryClauses()"
          :currentUser="currentUser"
          :currentUserName="currentUserName"
          :total="beneficiaryClausesTotal"
        >
          <template #headerRight>
            <SelectUser
              :setCurrentUser="setCurrentUser"
              :user="user"
              :currentUser="currentUser"
              :conjugalPartner="conjugalPartner"
            />
          </template>
        </PageBeneficiaryClauses>
      </div>
    </div>

    <div :style="{ display: showDonationsDetails ? 'block' : 'none' }">
      <div class="container">
        <Button
          theme="tertiary"
          iconSize="medium"
          v-on:click="setShowDonationsDetails(false)"
          class="button-back"
        >
          <ArrowLeft /> MA TRANSMISSION
        </Button>

        <div class="" v-if="showDonationsDetails">
          <PageDonations
            :headsOfFamily="[user?.customerId, conjugalPartner?._id]"
            :donations="donations"
            @updated="donationsUpdated"
            :currentUser="currentUser"
            :formatAmount="formatAmount"
            :getChartConfig="getChartConfig"
          />
        </div>
      </div>
    </div>

    <div
      :style="{
        display:
          showBeneficiaryClausesDetails || showDonationsDetails
            ? 'none'
            : 'block',
      }"
    >
      <div class="grid">
        <Card>
          <Header>Ma famille</Header>
          <Content ref="refContainerScrollFamily" style="overflow: hidden">
            <HorizontalList>
              <CardAvatar
                v-for="familyMember in family"
                :key="familyMember.customer.firstName"
                :src="getImageUrl(familyMember.customer._id)"
                :label="familyMember.customer.firstName"
              ></CardAvatar>
            </HorizontalList>
          </Content>

          <Arrows :refContainer="refContainerScrollFamily"></Arrows>
        </Card>

        <Card>
          <Header>
            Mon patrimoine successoral
            <TooltipClauses>
              Concernant vos contrats d’assurance vie souscrits après vos 70
              ans, seules les primes versées entrent dans le calcul de l’actif
              successoral soumis aux droits de successions. Les plus-values sont
              exonérées. Pour la présente simulation, seuls les versements
              réalisés sur vos contrats ont été comptabilisés, les rachats
              réalisés le cas échéant n’ont pas été pris en compte. Pour une
              estimation plus précise, nous nous tenons à votre disposition.
            </TooltipClauses>
          </Header>
          <Content>
            <ChartContainer
              :total="totalAssets"
              :legends="globalAssetsLegends"
              :legendsScrollable="true"
            >
              <canvas ref="chart_globalAssets"></canvas>
            </ChartContainer>
          </Content>
        </Card>

        <Card>
          <Header
            :showBtn="
              beneficiaryClausesIsLoading ? false : hasBeneficiaryClauses
            "
            v-on:click="setShowBeneficiaryClausesDetails(true)"
            >Mes clauses bénéficiaires</Header
          >
          <Content v-if="beneficiaryClausesIsLoading" class="aligner">
            <!--            <Loader></Loader>-->
          </Content>
          <Content v-else-if="!hasBeneficiaryClauses">
            <NoData>
              <Body2>
                Nous n'avons pas connaissance de l’existence d’un contrat
                d’assurance-vie. N’hésitez pas à nous communiquer l’ensemble des
                informations relatives aux contrats existants le cas échéant.
              </Body2>
              <a href="mailto:contact@kermony-office.com">
                <Button size="medium" theme="tertiary"
                  >CONTACTER KERMONY</Button
                >
              </a>
            </NoData>
          </Content>
          <Content
            :style="{ display: hasBeneficiaryClauses ? 'block' : 'none' }"
          >
            <ChartContainer
              :total="
                beneficiaryClausesTotal
                  ? formatAmount(beneficiaryClausesTotal, { cents: false })
                  : 0
              "
              :legends="beneficiaryClausesLegends"
              :legendsScrollable="true"
            >
              <canvas ref="chart_beneficiaryClauses"></canvas>
            </ChartContainer>
          </Content>
        </Card>

        <Card>
          <Header
            :showBtn="donationsIsLoading ? false : hasDonations"
            v-on:click="setShowDonationsDetails(true)"
            >Mes donations</Header
          >
          <Content v-if="donationsIsLoading" class="aligner">
            <!--            <Loader></Loader>-->
          </Content>
          <Content v-else-if="!hasDonations">
            <NoData>
              <Body2
                >A ce jour aucune donation n'a été portée à notre
                connaissance</Body2
              >
              <FormCreateDonation
                v-if="familyMembers.familyMembers !== undefined"
                buttonTitle="AJOUTER UNE DONATION"
                buttonStyle="right: 0;top:0;position:absolute"
                modal-title="Ajouter une autre donation"
                :addDonation="addDonation"
                :familyMembers="familyMembers.familyMembers"
              />
            </NoData>
          </Content>
          <Content :style="{ display: hasDonations ? 'block' : 'none' }"
            ><GraphDonations :donations="donations"></GraphDonations
          ></Content>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import OrgChart from "@/libs/balkangraph/orgchart.js";

import FamilyApi from "@/api/family";
import _ from "lodash";
import { mapState } from "vuex";
import PageBeneficiaryClauses from "@/views/App/Transmission/BeneficiaryClauses/PageBeneficiaryClauses";
import PageDonations from "@/views/App/Transmission/Donations/PageDonations";
import GraphDonations from "@/views/App/Transmission/Donations/GraphDonations";
import DonationsApi from "@/api/donation";
import SelectUser from "./SelectUser";
import Card from "@/components/Cards/Card";
import Header from "./Components/Header";
import ChartContainer from "@/views/App/Transmission/Components/ChartContainer";
import Content from "./Components/Content";
import { getChartConfig, toLegends } from "@/views/App/Transmission/ChartUtils";
import CardAvatar from "@/components/Cards/CardAvatar";
import HorizontalList from "@/views/App/Transmission/Components/HorizontalList";
import Arrows from "@/views/App/Transmission/Components/Arrows";
import Body2 from "@/components/Texts/Body2";
import Button from "@/components/Buttons/Button";
import NoData from "@/views/App/Transmission/Components/NoData";
import TooltipClauses from "./BeneficiaryClauses/TooltipClauses";
import FormCreateDonation from "@/views/App/Transmission/Donations/FormCreateDonation";
import { getAvatarUrl } from "@/utils/avatar";
import { formatAmount } from "@/utils/number";
import ArrowLeft from "@/views/App/Transmission/Components/ArrowLeft.vue";

export default {
  name: "tree",

  data() {
    return {
      getChartConfig,

      totalAssets: 0,
      globalAssets: null,
      globalAssetsLegends: [],
      globalAssetsChart: null,

      beneficiaryClauses: null,
      beneficiaryClausesTotal: null,
      beneficiaryClausesLegends: [],

      donations: null,

      conjugalPartner: null,
      familyOwner: null,

      currentUser: null,
      currentUserName: null,

      showBeneficiaryClausesDetails: false,

      beneficiaryClausesConjugalPartner: null,
      beneficiaryClausesUserLogged: null,

      showDonationsDetails: false,
      haveBeneficiaryClauses: false,
      familyMembers: null,
      family: null,
      refContainerScrollFamily: null,
    };
  },
  computed: {
    ...mapState(["user", "status"]),

    beneficiaryClausesIsLoading() {
      return this.getCurrentBeneficiaryClauses() === null;
    },
    hasBeneficiaryClauses() {
      return this.getCurrentBeneficiaryClauses()?.length > 0;
    },
    donationsIsLoading() {
      return this.donations === null;
    },
    hasDonations() {
      return this.donations?.length > 0;
    },
  },
  components: {
    ArrowLeft,
    FormCreateDonation,
    SelectUser,
    NoData,
    Button,
    Body2,
    Arrows,
    HorizontalList,
    CardAvatar,
    Content,
    ChartContainer,
    Header,
    Card,
    PageBeneficiaryClauses,
    PageDonations,
    GraphDonations,
    TooltipClauses,
  },

  methods: {
    getImageUrl: getAvatarUrl,
    async loadData() {
      this.familyMembers = await FamilyApi.getFamilyMembers();
      this.getFamilyData();
      this.getGlobalAssets();
      this.getDonations();
    },
    getCurrentBeneficiaryClauses() {
      if (this.currentUser === this.user.customerId) {
        return this.beneficiaryClausesUserLogged;
      } else {
        return this.beneficiaryClausesConjugalPartner;
      }
    },

    setShowBeneficiaryClausesDetails(b) {
      if (!this.hasBeneficiaryClauses && b) {
        return;
      }

      if (b) {
        this.$store.commit("updateHeader", {});
      } else {
        this.$store.commit("updateHeader", {
          title: "Ma transmission",
          subtitle: (
            <div class="aligner-v container-select-user">
              Retrouvez ici les informations liées à la transmission de{" "}
              <SelectUser
                setCurrentUser={this.setCurrentUser}
                user={this.user}
                currentUser={this.currentUser}
                conjugalPartner={this.conjugalPartner}
              />
            </div>
          ),
          subLegend:
            "Quelqu’un est assis à l’ombre aujourd’hui parce que quelqu’un a planté un arbre il y a longtemps",
        });
      }

      this.showBeneficiaryClausesDetails = b;
    },
    setShowDonationsDetails(b) {
      if (!this.hasDonations && b) {
        return;
      }

      if (b) {
        this.$store.commit("updateHeader", {});
      } else {
        this.$store.commit("updateHeader", {
          title: "Ma transmission",
          subtitle: (
            <div class="aligner-v container-select-user">
              Retrouvez ici les informations liées à la transmission de{" "}
              <SelectUser
                setCurrentUser={this.setCurrentUser}
                user={this.user}
                currentUser={this.currentUser}
                conjugalPartner={this.conjugalPartner}
              />
            </div>
          ),
          subLegend:
            "Quelqu’un est assis à l’ombre aujourd’hui parce que quelqu’un a planté un arbre il y a longtemps",
        });
      }

      this.showDonationsDetails = b;
    },

    setCurrentUser(currentUser) {
      this.currentUser = currentUser;

      if (currentUser === this.user.customerId) {
        this.drawChartBeneficiaryClauses(this.beneficiaryClausesUserLogged);
        this.currentUserName = this.user.customerName;
      } else {
        this.drawChartBeneficiaryClauses(
          this.beneficiaryClausesConjugalPartner
        );
        this.currentUserName = `${this.conjugalPartner.firstName} ${this.conjugalPartner.lastName}`;
      }

      // When user is changed, reload data
      this.getDonations();
      this.getGlobalAssets();
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    numberWithSpacesFloating(x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },

    formatAmount,
    addDonation(item) {
      this.donations = [...(this.donations || []), item];
    },
    donationsUpdated(donations) {
      this.donations = donations;
    },
    getDonations() {
      // Reset data
      this.donations = [];
      // Fetch data
      DonationsApi.getDonations(this.currentUser).then((donations) => {
        this.donations = donations;
      });
    },

    getGlobalAssets() {
      // Reset data
      this.totalAssets = 0;
      this.globalAssets = null;
      this.globalAssetsLegends = [];

      // Fetch data from server
      FamilyApi.getGlobalAssets(this.currentUser)
        .then((globalAssets) => {
          this.globalAssets = globalAssets;

          this.totalAssets += globalAssets.realEstateAssets;
          this.totalAssets += globalAssets.businessAssets;
          this.totalAssets += globalAssets.otherAssets;
          this.totalAssets += globalAssets.financialAssets;
          this.totalAssets += globalAssets.lifeInsurance;
          this.totalAssets += globalAssets.valuableObjects;

          this.totalAssets = this.formatAmount(this.totalAssets, {
            cents: false,
          });

          // chart_globalAssets
          let ctx = this.$refs.chart_globalAssets;

          // Chart labels
          let labels = [
            ["Patrimoine immobilier", globalAssets.realEstateAssets],
            ["Biens professionels", globalAssets.businessAssets],
            ["Autres biens", globalAssets.otherAssets],
            ["Actifs financiers", globalAssets.financialAssets],
            [
              "Primes d’assurance-vie versés après 70 ans",
              globalAssets.lifeInsurance,
            ],
            ["Biens autres", globalAssets.valuableObjects],
          ];

          labels = _.filter(labels, (item) => item[1] > 0);
          labels = _.map(labels, (item) => [
            item[0],
            this.formatAmount(item[1]),
          ]);

          // const colors = [
          //   "rgb(218,215,235)",
          //   "rgb(156,147,201)",
          //   "rgb(96,82,165)",
          //   "rgb(56,48,96)",
          //   "rgb(16,14,28)",
          // ];

          const colors = [
            "#919BC0",
            "#E8EAF2",
            "#5FADFF",
            "#1085FF",
            "#5C6AA0",
            "#3D476B",
          ];

          this.globalAssetsLegends = toLegends(labels, colors);

          // Chart data
          const data = {
            labels: labels,
            datasets: [
              {
                data: _.filter(
                  [
                    globalAssets.realEstateAssets,
                    globalAssets.businessAssets,
                    globalAssets.otherAssets,
                    globalAssets.financialAssets,
                    globalAssets.lifeInsurance,
                  ],
                  (item) => item > 0
                ),
                backgroundColor: colors,
              },
            ],
          };

          // Chart config
          const config = getChartConfig(data);
          if (_.isNil(this.globalAssetsChart)) {
            this.globalAssetsChart?.destroy();
            // eslint-disable-next-line no-undef,no-unused-vars
            this.globalAssetsChart = new Chart(ctx, config);
          } else {
            this.globalAssetsChart.options = config;
            this.globalAssetsChart?.update();
          }
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[getFamilyData]",
              "Error from API:",
              error.response.data.message
            );
        });
    },

    getBeneficiaryClauses() {
      FamilyApi.getBeneficiaryClauses()
        .then((beneficiaryClauses) => {
          //beneficiaryClauses = []

          this.$store.commit("updateHeader", {
            title: "Ma transmission",
            subtitle: (
              <div class="aligner-v container-select-user">
                Retrouvez ici les informations liées à la transmission de{" "}
                <SelectUser
                  setCurrentUser={this.setCurrentUser}
                  user={this.user}
                  currentUser={this.currentUser}
                  conjugalPartner={this.conjugalPartner}
                />
              </div>
            ),
            subLegend:
              "Quelqu’un est assis à l’ombre aujourd’hui parce que quelqu’un a planté un arbre il y a longtemps",
          });

          const beneficiaryClausesUserLogged = _.filter(
            beneficiaryClauses.data,
            (item) => {
              return (
                item.subscriber1._id === this.user.customerId ||
                item.subscriber2?._id === this.user.customerId
              );
            }
          );

          if (this.conjugalPartner !== null) {
            const beneficiaryClausesConjugalPartner = _.filter(
              beneficiaryClauses.data,
              (item) => {
                return (
                  item.subscriber1._id === this.conjugalPartner._id ||
                  item.subscriber2?._id === this.conjugalPartner._id
                );
              }
            );

            this.beneficiaryClausesConjugalPartner =
              beneficiaryClausesConjugalPartner;
          }

          this.beneficiaryClausesUserLogged = beneficiaryClausesUserLogged;

          this.drawChartBeneficiaryClauses(this.beneficiaryClausesUserLogged);
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[getFamilyData]",
              "Error from API:",
              error.response.data.message
            );
        });
    },

    drawChartBeneficiaryClauses(beneficiaryClauses) {
      let ctx = this.$refs.chart_beneficiaryClauses;
      if (ctx === null) {
        return;
      }

      this.haveBeneficiaryClauses = beneficiaryClauses.length > 0;

      let total = 0;
      let beneficiaries = {};

      _.each(beneficiaryClauses, (item) => {
        // Count the total without "amount" beneficiaries.
        let productTotal = _.reduce(
          item.beneficiaries,
          function (number, benef) {
            if (benef.kind === "amount") return number - benef.value;

            return number;
          },
          item.product.amount
        );

        // Loop on each beneficiary
        _.each(item.beneficiaries, (beneficiary) => {
          let amount;

          if (beneficiary.kind === "percentage")
            amount = (productTotal / 100) * beneficiary.value;
          else amount = beneficiary.value;

          if (item.multiplyAmountInGraph) {
            amount = productTotal;
            total += amount / item.beneficiaries.length;
          } else if (!item.ignoredFromTotal) {
            total += amount;
          }

          beneficiaries[beneficiary.beneficiary] =
            (beneficiaries[beneficiary.beneficiary] || 0) + amount;
        });
      });

      this.beneficiaryClausesTotal = total;

      // Chart labels
      const labels = _.map(beneficiaries, (value, key) => [
        key,
        this.formatAmount(value),
      ]);

      // const colors = [
      //   "#003770",
      //   "#979FBE",
      //   "#65739E",
      //   "#E5E5E5",
      //   "#2E4E7F",
      //   "#B1B6CE",
      //   "#4B5E8F",
      //   "#979FBE",
      //   "#E5E6ED",
      //   "#939CB9",
      // ];

      const colors = [
        "#20244E",
        "#C6C9E7",
        "#8D93D0",
        "#545DB9",
        "#002953",
        "#003770",
      ];

      this.beneficiaryClausesLegends = toLegends(labels, colors);

      // Chart data
      const data = {
        labels: labels,
        datasets: [
          {
            data: _.map(beneficiaries, (value) => value),
            backgroundColor: colors,
          },
        ],
      };

      const config = getChartConfig(data);
      this.beneficiaryChart?.destroy();
      // eslint-disable-next-line no-undef
      this.beneficiaryChart = new Chart(ctx, config);
    },

    getFamilyData() {
      FamilyApi.getFamilyData()
        .then((data) => {
          // Empty input
          this.family = data.family;

          this.family = this.family.map((item) => {
            let tags = [
              item.type === "partner" || item.type === "conjugal-partners"
                ? "partner"
                : item.type,
            ];

            if (item.isMainFamily) {
              tags.push("blue");
            }
            return {
              ...item,
              name: item.customer.firstName,
              title: item.customer.lastName,
              img: `https://eu.ui-avatars.com/api/?name=${item.customer.firstName}&length=1&background=90c0e3&color=FFF`,
              tags,
            };
          });

          const currentViewer = _.find(data.family, (item) => {
            return item.customer._id === this.user.customerId;
          });

          let conjugalPartner = _.find(data.family, (item) => {
            return item.pids.includes(currentViewer.id);
          });

          if (!conjugalPartner) conjugalPartner = null;

          if (conjugalPartner !== null) {
            const conjugalPartnerCustomer = _.get(
              conjugalPartner,
              "customer",
              null
            );
            if (
              _.get(
                _.find(this.familyMembers.familyMembers, {
                  _id: conjugalPartnerCustomer._id,
                }),
                "displaySensitiveDataToPartner"
              ) === true
            ) {
              this.conjugalPartner = conjugalPartnerCustomer;
            }
          }
          this.getBeneficiaryClauses();
        })
        .catch((error) => {
          if (error.response)
            console.log(
              "[getFamilyData]",
              "Error from API:",
              error.response.data.message
            );
        });
    },

    oc: function (domEl, x) {
      OrgChart.templates.family_template = Object.assign(
        {},
        OrgChart.templates.ana
      );
      OrgChart.templates.family_template.size = [86, 86];
      OrgChart.templates.family_template.plus = "";
      OrgChart.templates.family_template.minus = "";
      OrgChart.templates.family_template.rippleRadius = 40;
      OrgChart.templates.family_template.name =
        '<text style="font-size: 12px;" fill="#000000" x="43" y="100" text-anchor="middle">{val}</text>';
      OrgChart.templates.family_template.title =
        '<text style="font-size: 12px;" fill="#aeaeae" x="43" y="115" text-anchor="middle">{val}</text>';
      OrgChart.templates.family_template.img =
        '<clipPath id="{randId}"><circle cx="43" cy="43" r="38.5"></circle></clipPath></circle><image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="3" y="3"  width="80" height="80"></image>';
      OrgChart.templates.family_template.node =
        '<circle stroke-width="3" fill="none" stroke="#aeaeae" cx="43" cy="43" r="41.5"></circle>';
      OrgChart.templates.family_template.defs =
        '<g transform="matrix(0.05,0,0,0.05,-13,-15.5)" id="baby"><circle cx="260" cy="310" r="200" fill="#ffffff"></circle><path fill="#aeaeae" d="m468.64 268.32h-13.591c-18.432-89.348-95.612-157.432-189.139-161.798-.501-.185-1.015-.348-1.545-.482-18.363-4.622-31.188-22.595-31.188-43.707 0-17.828 14.468-32.333 32.252-32.333 12.573 0 22.802 10.258 22.802 22.866 0 8.284 6.716 15 15 15s15-6.716 15-15c0-29.15-23.687-52.866-52.802-52.866-34.326 0-62.252 27.962-62.252 62.333 0 17.876 5.828 34.443 15.769 47.432-80.698 15.127-144.725 78.25-161.291 158.555h-13.591c-24.103 0-43.712 19.596-43.712 43.683 0 24.086 19.609 43.682 43.712 43.682h14.692c20.935 89.871 101.582 157.018 197.596 157.018s176.66-67.148 197.596-157.018h14.692c24.103 0 43.712-19.596 43.712-43.682 0-24.087-19.609-43.683-43.712-43.683zm-265.054 55.257c-8.284-.024-14.981-6.758-14.958-15.043.007-2.337-.708-13.999-15.481-14.041-.026 0-.053 0-.08 0-14.697 0-15.475 11.62-15.481 13.953-.023 8.284-6.75 15.007-15.043 14.957-8.284-.024-14.98-6.759-14.957-15.043.038-13.322 5.349-25.101 14.955-33.166 8.223-6.904 19.065-10.702 30.543-10.702h.148c11.534.033 22.412 3.896 30.63 10.876 9.559 8.12 14.803 19.928 14.765 33.25-.023 8.27-6.735 14.957-14.999 14.957-.013.002-.027.002-.042.002zm52.766 129.374c-26.485 0-48.033-21.533-48.033-48.002 0-8.284 6.716-15 15-15s15 6.716 15 15c0 9.926 8.089 18.002 18.033 18.002s18.033-8.076 18.033-18.002c0-8.284 6.716-15 15-15s15 6.716 15 15c-.001 26.469-21.548 48.002-48.033 48.002zm113.765-129.374c-.015 0-.029 0-.044 0-8.284-.024-14.98-6.759-14.957-15.043.016-5.445-1.993-9.263-6.14-11.673-5.407-3.142-13.27-3.165-18.695-.053-4.161 2.387-6.191 6.193-6.207 11.638-.023 8.27-6.735 14.957-14.999 14.957-.015 0-.029 0-.043 0-8.284-.024-14.981-6.758-14.958-15.043.046-16.149 7.802-29.845 21.281-37.576 14.814-8.497 33.929-8.443 48.695.138 13.434 7.807 21.112 21.547 21.066 37.696-.023 8.271-6.735 14.959-14.999 14.959z"/> </g>';
      OrgChart.templates.family_template_blue = Object.assign(
        {},
        OrgChart.templates.family_template
      );
      OrgChart.templates.family_template_blue.node =
        '<circle stroke-width="3" fill="none" stroke="#ff9b20" cx="43" cy="43" r="41.5"></circle>';

      OrgChart.action.zoom = function (e) {
        e.preventDefault();
      };
      // mouseScrool: OrgChart.action.zoom,

      this.chart = new OrgChart(domEl, {
        // scaleInitial: 1.2,
        template: "family_template",
        // mouseScrool: OrgChart.action.zoom,
        scaleInitial: OrgChart.match.boundary,
        mouseScrool: OrgChart.action.xScroll,
        enableSearch: false,
        siblingSeparation: 100,
        nodes: x,
        nodeBinding: {
          field_0: "id",
          name: "name",
          title: "title",
          img: "img",
        },
        tags: {
          blue: {
            template: "family_template_blue",
          },
        },
      });

      // Hide default popup
      this.chart.on("click", function () {
        return false; //to cansel the click event
      });
    },
  },

  unmounted() {
    this.beneficiaryChart?.destroy();
  },
  mounted() {
    this.refContainerScrollFamily = this.$refs.refContainerScrollFamily.$el;
    this.$store.commit("updateHeader", {
      title: "Ma transmission",
      subtitle: (
        <div style="height: 32px" class="aligner-v">
          Retrouvez ici les informations liées à la transmission de
        </div>
      ),
      subLegend:
        "Quelqu’un est assis à l’ombre aujourd’hui parce que quelqu’un a planté un arbre il y a longtemps",
    });
    this.currentUser = this.user.customerId;
    this.currentUserName = this.user.customerName;

    this.loadData();
  },
};
</script>
<style scoped>
.family-owners {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;
}

.family-owners div {
}

.grid > .card {
  padding: 16px;
  display: grid;
  gap: 16px;
  grid-template-rows: auto minmax(0, 1fr);
  min-height: 244px;
  height: 246px;
  /*min-height: 250px;*/
}

button.button-back {
  position: absolute;
  margin-top: -105px;
  color: var(--kermony-office-bleu-1);
  font-weight: 400;
}

button.button-back svg {
  stroke: #21244e;
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .grid > .card {
    height: initial !important;
  }

  button.button-back {
    margin-top: -60px !important;
    color: var(--kermony-blanc);
    padding-left: 0;
  }

  button.button-back svg {
    stroke: var(--kermony-blanc);
  }
}

.container-select-user {
  gap: 6px;
}
</style>
