// deduction available
import HTTP from "@/axios";

export default {
  getValuableObjects() {
    return new Promise((resolve, reject) => {
      HTTP.get("valuable_object/")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createValuableObject(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("valuable_object/", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editValuableObject(id, data) {
    return new Promise((resolve, reject) => {
      HTTP.put(`valuable_object/${id}/`, data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteValuableObject(id) {
    return new Promise((resolve, reject) => {
      HTTP.delete(`valuable_object/${id}/`)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
