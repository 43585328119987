const toOption = (label, key) => ({
  label: _.capitalize(label),
  value: key,
});

const toOptions = (labels, alphabeticalOrder = false) => {
  if (alphabeticalOrder) {
    return _.chain(labels).map(toOption).orderBy(["label"]).value();
  }

  return _.map(labels, toOption);
};

//-------------------------------------------
//                 Kind
//-------------------------------------------
/*
-donation simple
-donation partage
-don manuel
-autre
 */
import _ from "lodash";

const SIMPLE_DONATION = "donation-simple";
const SHARED_DONATION = "donation-partage";
const MANUAL_DONATION = "don-manuel";
//const OTHER_DONATION = "autre";

export const kindLabels = {
  [SIMPLE_DONATION]: "donation simple",
  [SHARED_DONATION]: "donation partage",
  [MANUAL_DONATION]: "don manuel",
  //[OTHER_DONATION]: "autre",
};

export const kindOptions = toOptions(kindLabels);

//-------------------------------------------
//                 Nature
//-------------------------------------------
/*
-donation mobilière
-donation immobilière
 */
const REAL_ESTATE_DONATION = "donation-mobiliere";
const FURNITURE_DONATION = "donation-immobiliere";

export const natureLabels = {
  [REAL_ESTATE_DONATION]: "donation mobilière",
  [FURNITURE_DONATION]: "donation immobilière",
};

export const natureOptions = toOptions(natureLabels);

//-------------------------------------------
//       Variety / Forme de la donation
//-------------------------------------------
/*
-avance sur héritage (avancement d'hoirie)
-par préciput
-hors part (dispense de rapport)
 */
const AVANCE_SUR_HERITAGE = "avance-sur-heritage";
const PAR_PRECIPUT = "par-preciput";
const HORS_PART = "hors-part";

export const varietyLabels = {
  [AVANCE_SUR_HERITAGE]: "avance sur héritage (avancement d'hoirie)",
  [PAR_PRECIPUT]: "par préciput",
  [HORS_PART]: "hors part (dispense de rapport)",
};

export const varietyOptions = toOptions(varietyLabels);

//----------------------------------------------------------------
//       AssetKind / Bien concerné par la donation
//----------------------------------------------------------------
/*
-une somme d'argent
-un bien déjà enregistré (mettre ensuite la liste et enlever le champ valeur)
-un bien immobilier
-un bien mobilier
-autre
 */

const MONEY = "une-somme-d-argent";
export const VALUABLE_OBJECT_ALREADY_REGISTERED = "un-bien-deja-renseigne";
const REAL_ESTATE = "un-bien-immobilier";
const FURNITURE = "un-bien-mobilier";
const OTHER = "autre";

export const assetLabels = {
  [MONEY]: "une somme d'argent",
  [VALUABLE_OBJECT_ALREADY_REGISTERED]: "un bien déjà renseigné",
  [REAL_ESTATE]: "un bien immobilier",
  [FURNITURE]: "un titre de société",
  [OTHER]: "autre",
};

export const assetOptions = toOptions(assetLabels);

//----------------------------------------------------------------
//                    GivenIn / Donné en
//----------------------------------------------------------------
/*
-pleine-propriété
-nue-propriété
-usufruit
-nue-propriété avec réserve d'usufruit
 */

const PLEINE_PROPRIETE = "pleine-propriete";
const NUE_PROPRIETE = "nue-propriete";
const USUFRUIT = "usufruit";
const NUE_PROPRIETE_AVEC_RESERVE_USUFRUIT =
  "nue-propriete-avec-reserve-d-usufruit";

export const givenInLabels = {
  [PLEINE_PROPRIETE]: "pleine-propriété",
  [NUE_PROPRIETE]: "nue-propriété",
  [USUFRUIT]: "usufruit",
  [NUE_PROPRIETE_AVEC_RESERVE_USUFRUIT]:
    "nue-propriété avec réserve d'usufruit",
};

export const givenInOptions = toOptions(givenInLabels);
