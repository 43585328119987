<template>
  <div class="horizontal-list"><slot></slot></div>
</template>

<script>
export default {
  name: "HorizontalList",
};
</script>

<style scoped>
.horizontal-list {
  display: flex;
  gap: 8px;
}
</style>
