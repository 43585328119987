<template>
  <Workspace class="position-relative">
    <template #headerLeft>
      <Heading5 style="margin-bottom: 4px">Mes donations</Heading5>
    </template>

    <div class="grid">
      <Card>
        <Header>Mes donations</Header>
        <Content v-if="!hasDonations" style="height: 153px">
          <NoData>
            <Body2>Vous n’avez pas de donations pour l’instant.</Body2>
          </NoData>
        </Content>
        <Content v-else>
          <GraphDonations
            v-if="computedDonations !== null"
            :donations="computedDonations"
          />
        </Content>
      </Card>
      <Card>
        <Header>Abattement disponible</Header>
        <div>En cours de développement</div>
      </Card>
    </div>

    <Donations
      v-if="computedDonations !== null"
      :donations="computedDonations"
      :headsOfFamily="headsOfFamily"
      @updated="onUpdate"
    />
  </Workspace>
</template>

<script>
import Donations from "./Donations";
import GraphDonations from "@/views/App/Transmission/Donations/GraphDonations";
import Card from "@/components/Cards/Card";
import Workspace from "@/components/Workspaces/Workspace";
import Heading5 from "@/components/Texts/Heading5";
import Content from "@/views/App/Transmission/Components/Content";
import Header from "../Components/Header";
import NoData from "@/views/App/Transmission/Components/NoData";
import Body2 from "@/components/Texts/Body2";

export default {
  name: "PageDonations",
  components: {
    Body2,
    NoData,
    Header,
    Content,
    Heading5,
    Workspace,
    Card,
    GraphDonations,
    Donations,
  },
  props: [
    "donations",
    "formatAmount",
    "getChartConfig",
    "currentUser",
    "headsOfFamily",
  ],
  computed: {
    hasDonations() {
      return this.donations?.length > 0;
    },
    computedDonations: {
      get: function () {
        return this.donations;
      },
      set: function (newOptions) {
        this.$emit("updated", newOptions);
      },
    },
  },
  data() {
    return {
      maxDeduction: null,
    };
  },
  methods: {
    onUpdate(donations) {
      this.computedDonations = donations;
    },
  },
};
</script>

<style scoped></style>
