<template>
  <div
    v-if="
      computedDonations !== null &&
      familyMembers !== null &&
      valuableObjects !== null
    "
    style="margin-top: 24px"
  >
    <div>
      <Heading6 v-if="donations?.length > 0">Liste des donations</Heading6>

      <div class="hack-btn">
        <FormCreateDonation
          v-if="!isMobile"
          buttonTitle="Ajouter une donation"
          buttonStyle="right: 0;top:0;position:absolute"
          modal-title="Ajouter une autre donation"
          :addDonation="addDonation"
          :familyMembers="familyMembers"
          :headsOfFamily="headsOfFamily"
        />
        <FormEditDonation
          v-if="currentEdit !== null"
          :donation="currentEdit"
          :onHide="stopEdit"
          :updateDonation="updateDonation"
          :familyMembers="familyMembers"
          :headsOfFamily="headsOfFamily"
        />
      </div>
    </div>

    <div class="container-grid" style="margin-top: 8px">
      <CardItem
        v-for="donation in computedDonations"
        :key="donation"
        :title="capitalize(kindLabels[donation.kind])"
        icon-name="branch"
      >
        <template v-slot:action>
          <ContextualMenu
            :donation="donation"
            :deleteDonation="deleteDonation"
            :editDonation="editDonation"
          />
        </template>

        <template #topLeft>
          <Body1>{{ donation.name }}</Body1>
        </template>
        <template #topRight>
          <Heading6 style="color: var(--kermony-office-rose)"
            ><div
              v-if="
                donation.intrinsicValue !== null &&
                donation.intrinsicValue !== undefined
              "
            >
              {{ formatAmount(donation.intrinsicValue) }}
            </div>
            <div
              v-if="
                donation.valuableObject !== null &&
                donation.valuableObject !== undefined
              "
            >
              {{ getAmountOfValuableObject(donation.valuableObject) }}
            </div></Heading6
          >
        </template>

        <template #bottomLeft>
          <Caption>Donataires</Caption>
        </template>

        <template #bottomRight>
          <ListAvatarCircle
            :items="beneficiariesToUrls(donation.beneficiaries)"
          />
        </template>
      </CardItem>
      <FormCreateDonation
        v-if="isMobile"
        class="wrapper-btn-mobile-full-width"
        buttonTitle="Ajouter une donation"
        buttonStyle="right: 0;top:0;position:absolute"
        modal-title="Ajouter une autre donation"
        :addDonation="addDonation"
        :familyMembers="familyMembers"
        :headsOfFamily="headsOfFamily"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import DonationsApi from "@/api/donation";
import FormCreateDonation from "@/views/App/Transmission/Donations/FormCreateDonation";
import FamilyApi from "@/api/family";
import ValuableObjectApi from "@/api/valuableObject";
import { kindLabels } from "@/views/App/Transmission/Donations/constants";
import { formatAmount } from "@/utils/number";
import FormEditDonation from "@/views/App/Transmission/Donations/FormEditDonation";
import ContextualMenu from "@/views/App/Transmission/Donations/ContextualMenu";
import Heading6 from "@/components/Texts/Heading6";
import CardItem from "@/components/Cards/CardItem";
import Body1 from "@/components/Texts/Body1";
import Caption from "@/components/Texts/Caption";
import ListAvatarCircle from "@/components/Avatars/ListAvatarCircle";
import { getAvatarUrl } from "@/utils/avatar";
import { isMobile } from "@/utils/isMobile";

export default {
  name: "Donations",
  components: {
    ListAvatarCircle,
    Caption,
    Body1,
    CardItem,
    Heading6,
    ContextualMenu,
    FormEditDonation,
    FormCreateDonation,
  },
  props: ["donations", "headsOfFamily"],
  data() {
    return {
      visible: false,
      currentEdit: null,
      familyMembers: null,
      valuableObjects: null,
      kindLabels,
      capitalize: _.capitalize,
      formatAmount,
      isMobile: isMobile(),
    };
  },
  computed: {
    computedDonations: {
      get: function () {
        return this.donations;
      },
      set: function (newOptions) {
        this.$emit("updated", newOptions);
      },
    },
    beneficiariesToString() {
      return (beneficiaries) => {
        const ids = _.map(beneficiaries, (b) => b.beneficiary);
        return _.chain(this.familyMembers)
          .filter((member) => ids.indexOf(member._id) !== -1)
          .map((member) => `${member.firstName} ${member.lastName}`)
          .join(", ")
          .value();
      };
    },

    beneficiariesToUrls() {
      return (beneficiaries) => {
        const ids = _.map(beneficiaries, (b) => b.beneficiary);
        return _.chain(this.familyMembers)
          .filter((member) => ids.indexOf(member._id) !== -1)
          .map((member) => ({
            src: getAvatarUrl(member._id),
            label: member.firstName,
          }))
          .value();
      };
    },
    getAmountOfValuableObject() {
      return (valuableObjectId) => {
        try {
          return formatAmount(
            _.find(this.valuableObjects, { _id: valuableObjectId }).currentValue
          );
        } catch (e) {
          return "";
        }
      };
    },
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    addDonation(item) {
      this.computedDonations.push(item);
    },
    updateDonation(item) {
      this.computedDonations = _.map(this.computedDonations, (donation) => {
        if (donation._id === item._id) {
          return item;
        }

        return { ...donation };
      });
    },
    deleteDonation(item) {
      DonationsApi.deleteDonation(item._id).then(() => {
        this.computedDonations = _.filter(
          this.computedDonations,
          (Donations) => Donations._id !== item._id
        );
      });
    },
    editDonation(item) {
      this.currentEdit = item;
    },
    stopEdit() {
      this.currentEdit = null;
    },
  },
  mounted() {
    FamilyApi.getFamilyMembers().then((data) => {
      this.familyMembers = data.familyMembers;
    });

    ValuableObjectApi.getValuableObjects().then((data) => {
      this.valuableObjects = data;
    });
  },
};
</script>

<style scoped>
.item-donation:hover .action {
  display: block;
}
.action {
  display: none;
  position: absolute;
  right: 15px;
}

.card-body-custom {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(207, 212, 226);
  padding: 15px !important;
}

.container-grid {
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  display: grid;
}

/* Mobile only */
@media only screen and (pointer: coarse) and (min-device-width: 320px) and (max-device-width: 480px) {
  .container-grid {
    grid-template-columns: none !important;
  }
}

.hack-btn {
  margin: 16px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
