<template>
  <div>
    <Button
      @click="visible = true"
      size="medium"
      theme="primary"
      icon-left="plus"
      >{{ buttonTitle }}</Button
    >
    <ModalFormDonation
      :modalTitle="modalTitle"
      :familyMembers="familyMembers"
      :headsOfFamily="headsOfFamily"
      v-model:formState="formState"
      v-model:activeKey="activeKey"
      v-model:visible="visible"
      v-model:formRef="formRef"
      :onOk="onOk"
      :onCancel="onCancel"
      textConfirmButton="CONFIRMER LA DONATION"
    />
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "vue";
import DonationApi from "@/api/donation";

import { VALUABLE_OBJECT_ALREADY_REGISTERED } from "@/views/App/Transmission/Donations/constants";
import Button from "@/components/Buttons/Button";
import ModalFormDonation from "@/views/App/Transmission/Donations/Form/ModalFormDonation";
import { catchSubmitForm } from "@/views/App/ValuableObjects/utils";
export default defineComponent({
  name: "FormCreateDonation",
  components: {
    ModalFormDonation,
    Button,
  },
  props: [
    "buttonTitle",
    "buttonStyle",
    "modalTitle",
    "addDonation",
    "familyMembers",
    "headsOfFamily",
    "btnClass",
  ],
  data() {
    return {
      VALUABLE_OBJECT_ALREADY_REGISTERED,
    };
  },
  setup(props) {
    const activeKey = ref(1);

    const formRef = ref();
    const visible = ref(false);
    const formState = reactive({
      name: null,
      kind: null,
      nature: null,
      isValidatedByNotary: null,
      isRegistered: null,
      donationDate: null,
      //variety: null,
      assetKind: null,
      valuableObject: null,
      intrinsicValue: null,
      proportionGiven: null,
      givenIn: null,
      donor: null,
      beneficiaries: [],
      comment: null,
    });

    const resetFields = () => {
      formState.name = null;
      formState.kind = null;
      formState.nature = null;
      formState.isValidatedByNotary = null;
      formState.isRegistered = null;
      formState.donationDate = null;
      formState.assetKind = null;
      formState.valuableObject = null;
      formState.intrinsicValue = null;
      formState.proportionGiven = null;
      formState.givenIn = null;
      formState.donor = null;
      formState.beneficiaries = [];
      formState.comment = null;
    };

    const onOk = () => {
      formRef.value
        .validateFields()
        .then(() => {
          let formValues = { ...formState };

          if (formValues.assetKind === VALUABLE_OBJECT_ALREADY_REGISTERED) {
            formValues.intrinsicValue = null;
          } else {
            formValues.valuableObject = null;
          }

          DonationApi.createDonation(formValues)
            .then((data) => {
              props.addDonation(data);
            })
            .catch(catchSubmitForm);

          visible.value = false;
          activeKey.value = 1;
          resetFields();
        })
        .catch((error) => {
          // Form invalid, e.g. field required without value

          alert("Tous les champs ne sont pas complétés");
          // TODO redirect to "first" tab with an error like "company create form"
          console.error("[Donation] Form invalid:", error);
        });
    };

    const onCancel = () => {
      visible.value = false;
      activeKey.value = 1;
      resetFields();
    };

    return {
      formState,
      formRef,
      visible,
      onOk,
      onCancel,
      activeKey,
    };
  },
});
</script>
