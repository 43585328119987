<template>
  <div class="wrapper-no-data-container">
    <div class="wrapper-no-data">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoData",
};
</script>

<style scoped>
.wrapper-no-data-container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100%;
}
.wrapper-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
</style>
