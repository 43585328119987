import HTTP from "@/axios";

export default {
  getDonations() {
    return new Promise((resolve, reject) => {
      HTTP.get("family/donations")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getBeneficiaryClauses() {
    return new Promise((resolve, reject) => {
      HTTP.get("family/beneficiary_clauses")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getGlobalAssets(selectedUser) {
    return new Promise((resolve, reject) => {
      HTTP.get("family/global_assets", { params: { selectedUser } })
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getFamilyData() {
    return new Promise((resolve, reject) => {
      HTTP.get("family/data")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getFamilyMembers() {
    return new Promise((resolve, reject) => {
      HTTP.get("family/members")
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createFamilyMember(data) {
    return new Promise((resolve, reject) => {
      HTTP.post("family/members", data)
        .then((response) => {
          if (response) return resolve(response.data);

          return resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
