<template>
  <a-tooltip :placement="placement ?? 'topRight'">
    <template #title>
      <slot name="title"></slot>
    </template>
    <slot></slot>
  </a-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  props: ["placement"],
};
</script>

<style>
.ant-tooltip-arrow-content {
  background: #21244e !important;
  /* border-radius: 5px; */
}

.ant-tooltip-inner {
  background: #21244e !important;
  border-radius: 5px;
}
.ant-tooltip {
  max-width: 400px;
}
</style>
