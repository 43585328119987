<template>
  <Card>
    <div class="item">
      <div class="action">
        <slot name="action"></slot>
      </div>

      <div>
        <div style="display: flex; align-items: center; margin-bottom: 20px">
          <Icon
            :name="iconName"
            size="thin"
            color="var(--kermony-office-violet)"
          />
          <h4 class="title">{{ title }}</h4>
        </div>

        <div class="top">
          <div><slot name="topLeft"></slot></div>
          <div><slot name="topRight"></slot></div>
        </div>
        <div class="bottom">
          <div><slot name="bottomLeft"></slot></div>
          <div><slot name="bottomRight"></slot></div>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/Cards/Card";
import Icon from "@/components/Icons/Icon";
export default {
  name: "CardItem",
  components: { Icon, Card },
  props: ["title", "iconName"],
};
</script>

<style scoped>
.item {
  position: relative;
  padding: 16px;
}

.item svg {
  width: 16px;
  height: 16px;
}

.item .title {
  margin-left: 4px;
  margin-bottom: 0;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--kermony-office-violet);
}

.item .top {
  display: flex;
  align-items: center;
}

.item .top :last-child {
  margin-left: auto;
}

.item .bottom {
  display: flex;
  align-items: center;
}

.item .bottom :last-child {
  margin-left: auto;
}

.item:hover .action {
  display: block;
}

.action {
  display: none;
  position: absolute;
  right: 15px;
  margin-top: -4px;
}
</style>
