<template>
  <a-form-item
    name="isValidatedByNotary"
    label="La donation est-elle passée devant le notaire ?"
  >
    <a-radio-group v-model:value="computedFormState.isValidatedByNotary">
      <a-radio :value="true">Oui</a-radio>
      <a-radio :value="false">Non</a-radio>
      <a-radio :value="null">Je ne sais pas</a-radio>
    </a-radio-group>
  </a-form-item>

  <a-form-item
    name="isRegistered"
    label="La donation a-t-elle été enregistrée auprès de l'administration fiscale ?"
  >
    <a-radio-group
      v-model:value="computedFormState.isRegistered"
      :disabled="computedFormState.isValidatedByNotary === true"
    >
      <a-radio :value="true">Oui</a-radio>
      <a-radio :value="false">Non</a-radio>
      <a-radio :value="null">Je ne sais pas</a-radio>
    </a-radio-group>
  </a-form-item>
</template>

<script>
export default {
  name: "TabRegistration",
  props: ["formState"],
  watch: {
    "computedFormState.isValidatedByNotary": {
      handler(val) {
        if (val === true) {
          this.computedFormState.isRegistered = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update", newOptions);
      },
    },
  },
};
</script>

<style scoped></style>
