<template>
  <a-form
    layout="vertical"
    autocomplete="off"
    ref="formRef"
    :model="computedFormState"
    name="form_in_modal"
  >
    <a-tabs
      v-model:activeKey="computedActiveKey"
      animated
      style="margin-top: -16px"
    >
      <a-tab-pane :key="1" tab="Informations">
        <TabInformations v-model:formState="computedFormState" />
      </a-tab-pane>

      <a-tab-pane :key="2" tab="Enregistrement">
        <TabRegistration v-model:formState="computedFormState" />
      </a-tab-pane>

      <!-- Page 3 -->
      <a-tab-pane :key="3" tab="Objet de la donation">
        <TabObjectOfDonation
          v-model:formState="computedFormState"
          :familyMembers="familyMembers"
          :headsOfFamily="headsOfFamily"
        />
      </a-tab-pane>
      <a-tab-pane :key="4" tab="Bénéficiaires">
        <TabBeneficiaries
          v-model:formState="computedFormState"
          :familyMembers="familyMembers"
        />
      </a-tab-pane>
    </a-tabs>
  </a-form>
</template>

<script>
import TabInformations from "@/views/App/Transmission/Donations/Form/Tabs/TabInformations";
import TabRegistration from "@/views/App/Transmission/Donations/Form/Tabs/TabRegistration";
import TabObjectOfDonation from "@/views/App/Transmission/Donations/Form/Tabs/TabObjectOfDonation";
import TabBeneficiaries from "@/views/App/Transmission/Donations/Form/Tabs/TabBeneficiaries";
export default {
  name: "BaseFormDonation",
  components: {
    TabBeneficiaries,
    TabObjectOfDonation,
    TabRegistration,
    TabInformations,
  },
  mounted() {
    this.computedFormRef = this.$refs.formRef;
  },
  props: [
    "formState",
    "familyMembers",
    "activeKey",
    "formRef",
    "headsOfFamily",
  ],
  computed: {
    computedFormState: {
      get: function () {
        return this.formState;
      },
      set: function (newOptions) {
        this.$emit("update:formState", newOptions);
      },
    },
    computedActiveKey: {
      get: function () {
        return this.activeKey;
      },
      set: function (newOptions) {
        this.$emit("update:activeKey", newOptions);
      },
    },
    computedFormRef: {
      get: function () {
        return this.formRef;
      },
      set: function (newOptions) {
        this.$emit("update:formRef", newOptions);
      },
    },
  },
};
</script>

<style scoped></style>
